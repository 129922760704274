<template>
  <!-- 收银订单页面 -->
  <div class="container">
    <a-row class="right-content-top" :gutter="10">
      <a-col :span="6" class="header-info">
        <div class="bg-info">
          <img src="@/assets/icons/icon_04.png" alt="" />
          <div class="icon-wrapper">
            <h1>{{ topInfoList.price }}</h1>
            <span>总充值金额</span>
          </div>
        </div>
      </a-col>
      <a-col :span="6" class="header-info">
        <div class="bg-info">
          <img src="@/assets/icons/icon_02.png" alt="" />
          <div class="icon-wrapper">
            <h1>{{ topInfoList.withPrice }}</h1>
            <span>已提现金额</span>
          </div>
        </div>
      </a-col>
      <a-col :span="6" class="header-info">
        <div class="bg-info">
          <img src="@/assets/icons/icon_05.png" alt="" />
          <div class="icon-wrapper">
            <h1>{{ topInfoList.coin }}</h1>
            <span>产币数量</span>
          </div>
        </div>
      </a-col>
      <a-col :span="6" class="header-info">
        <div class="bg-info">
          <img src="@/assets/icons/icon_03.png" alt="" />
          <div class="icon-wrapper">
            <h1>{{ topInfoList.surplusCoin }}</h1>
            <span>剩余产币</span>
          </div>
        </div>
      </a-col>
    </a-row>
    <a-row>
      <a-col :span="24" class="right">
        <div class="right-content">
          <!-- 筛选 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="40">
                <!-- 订单号 -->
                <a-col :md="5" :sm="24">
                  <a-form-item label="关键词">
                    <a-input v-model="queryParam.keyword" allow-clear placeholder="关键词搜索" />
                  </a-form-item>
                </a-col>
                <!-- 用户昵称 -->
                <!-- <a-col :md="5" :sm="24">
									<a-form-item label="关键词">
										<a-input v-model="queryParam.keyword" allow-clear placeholder="关键词搜索" />
									</a-form-item>
								</a-col> -->
                <a-col :md="8" :sm="24">
                  <a-form-item label="时间">
                    <a-range-picker allow-clear v-model="queryParam.time" show-time style="width: 320px" />
                  </a-form-item>
                </a-col>
                <a-col :md="6" :sm="24">
                  <a-button type="primary" @click="initTableData">搜索</a-button>
                  <a-button style="margin-left: 8px" @click="() => (queryParam = {})">重置</a-button>
                  <a-button style="margin-left: 8px" @click="exportListData" type="primary">导出 </a-button>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="data"
            :scroll="{ x: 1300 }"
            :loading="loading"
            :pagination="false"
            rowKey="id"
          >
            <!-- 订单类型插槽 -->
            <span slot="objectType" slot-scope="text">
              {{ text == 1 ? '普通订单收益' : text == 2 ? '拍图订单收益' : '礼物扣除' }}
            </span>
            <span slot="payType" slot-scope="text">{{
              text == 1 ? '支付宝支付' : text == 2 ? '微信支付' : text == 3 ? '苹果内购' : ''
            }}</span>
          </a-table>
          <!-- 分页组件 -->
          <div style="margin-top: 20px; display: flex; flex-direction: row-reverse">
            <a-pagination
              :pageSize="page.pageSize"
              :current="page.pageNo"
              show-size-changer
              :page-size-options="['10', '20', '50', '100']"
              show-quick-jumper
              :total="total"
              :show-total="(total) => `共 ${total} 条`"
              @change="pageChange"
              @showSizeChange="sizeChange"
            >
            </a-pagination>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { findRechargeRecordPageList, getRechargeRecordData } from '@/api/modular/mallLiving/rechargeRecord'

const columns = [
  {
    title: '序号',
    align: 'center',
    width: '100px',
    key: 'number',
    customRender: (text, record, index) => `${index + 1}`,
    width: 80,
  },
  {
    title: 'ID',
    align: 'center',
    dataIndex: 'idNo',
    key: 'idNo',
    width: '100px',
  },
  {
    title: '昵称',
    align: 'center',
    dataIndex: 'nickname',
    key: 'nickname',
    width: '100px',
  },
  {
    title: '手机号',
    align: 'center',
    dataIndex: 'mobile',
    key: 'mobile',
    width: '100px',
  },
  {
    title: '支付方式',
    dataIndex: 'payType',
    key: 'payType',
    align: 'center',
    scopedSlots: {
      customRender: 'payType',
    },
    width: '120px',
  },
  {
    title: '支付金额',
    dataIndex: 'payPrice',
    key: 'payPrice',
    align: 'center',
    width: '120px',
  },
  {
    title: '产币数量',
    dataIndex: 'coin',
    align: 'center',
    width: '100px',
  },
  {
    title: '创建时间',
    dataIndex: 'createTime',
    key: 'createTime',
    align: 'center',
    width: '190px',
  },
]

export default {
  data() {
    return {
      columns,
      data: [],
      page: {
        pageNo: 2,
        pageSize: 10,
      },
      total: 0,
      loading: false,
      queryParam: {},
      topInfoList: {},
    }
  },
  mounted() {
    this.initTableData()
  },
  methods: {
    //切换页面
    pageChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    sizeChange(pageNo, pageSize) {
      this.page.pageNo = pageNo
      this.page.pageSize = pageSize
      this.getList()
    },
    initTableData() {
      this.page.pageNo = 1
      this.getList()
    },
    //时间处理函数
    onChange(date) {
      var date = new Date(date).getTime()
      // var year = date.getFullYear()
      // var month = date.getMonth() + 1
      // var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
      return date
    },
    getList() {
      this.loading = true
      if (this.queryParam.time && this.queryParam.time.length == 2) {
        this.queryParam.startTime = this.onChange(this.queryParam.time[0]._d)
        this.queryParam.endTime = this.onChange(this.queryParam.time[1]._d)
      } else {
        this.queryParam.startTime = ''
        this.queryParam.endTime = ''
      }
      this.queryParam.isExport = 1
      var params = {
        page: this.page,
        queryParam: this.queryParam,
      }
      findRechargeRecordPageList(params)
        .then((res) => {
          if (res.success) {
            this.data = res.data.rows
            this.total = res.data.totalRows
            this.loading = false
          }
        })
        .finally((res) => {
          setTimeout(() => {
            this.loading = false
          }, 5000)
        })
      getRechargeRecordData(params).then((res) => {
        if (res.success) {
          this.topInfoList = res.data ? res.data : 0
          console.log(res.data)
        }
      })
    },

    exportListData() {
      this.loading = true
      if (this.queryParam.time && this.queryParam.time.length == 2) {
        this.queryParam.startTime = this.onChange(this.queryParam.time[0]._d)
        this.queryParam.endTime = this.onChange(this.queryParam.time[1]._d)
      } else {
        this.queryParam.startTime = ''
        this.queryParam.endTime = ''
      }
      var params = this.queryParam
      params.isExport = 2
      this.axios
        .post(`/rechargeRecord/page`, params, {
          responseType: 'blob', //服务器响应的数据类型，可以是 'arraybuffer', 'blob', 'document', 'json', 'text', 'stream'，默认是'json'
        })
        .then((res) => {
          if (!res) return
          const blob = new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          }) // 构造一个blob对象来处理数据，并设置文件类型

          const href = URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
          const a = document.createElement('a') //创建a标签
          a.style.display = 'none'
          a.href = href // 指定下载链接
          a.download = '用户充值记录_' + new Date().getTime() + '.xlsx' //指定下载文件名
          a.click() //触发下载
          URL.revokeObjectURL(a.href) //释放URL对象
        })
        .catch((err) => {
          console.log(err)
        })
        .finally((fin) => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  min-width: 1000px;
}

.title {
  color: rgba(16, 16, 16, 100);
  font-size: 14px;
  font-weight: bold;
}

.right {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 100);
}

.right-content {
  box-sizing: border-box;
  padding: 20px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

img {
  width: 20px;
  height: 20px;
}

.right-content-top {
  box-sizing: border-box;
  padding-bottom: 20px;
}

.icon {
  font-size: 20px;
  margin: 0 5px;
}

// 顶部信息栏样式
.header-info {
  .bg-info {
    background: rgba(255, 255, 255, 100);
    padding: 5px;

    img {
      width: 95px;
      height: 107px;
    }

    .icon-wrapper {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;

      h1 {
        font-size: 23px;
        line-height: 25px;
      }
    }
  }
}
</style>
