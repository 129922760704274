//平台分佣收益接口
import { axios } from '@/utils/request'


/*
 * @Description: 用户充值记录分页查询
 * @Version: 
 * @Author: 陈莉莉
 * @Date: 2022-05-02
 */
export function findRechargeRecordPageList(parameter){
	return axios({
		url: "/rechargeRecord/page",
		method: 'post',
		params:parameter.page,
		data: parameter.queryParam
	})
}

/**
 * @param {Object} parameter
 * @Description: 用户充值统计
 * @Version: 
 * @Author: 陈莉莉
 * @Date: 2022-05-02
 */
export function getRechargeRecordData(parameter){
	return axios({
		url: '/rechargeRecord/totalPrice',
		method: "post",
		data: parameter
	})
}